<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl">Respostas</h1>
    <p class="text-red-500 font-medium my-2">Total: {{ total }}</p>
    <form class="mb-2">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <label for="de" class="block text-sm font-medium">A partir de</label>
          <input
            v-model="form.de"
            type="datetime-local"
            name="de"
            id="de"
            autocomplete="given-name"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <label for="ate" class="block text-sm font-medium">Até</label>
          <input
            v-model="form.ate"
            type="datetime-local"
            name="ate"
            id="ate"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="md:col-span-3 2xl:col-span-2">
          <label for="dataDisparo" class="block text-sm font-medium text-gray-700">
            Centro de custo</label
          >
          <v-select v-model="centrocusto" :options="centroscusto" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
        </div>
        <div class="col-span-12 md:col-span-2">
          <button
            @click="list"
            type="button"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Buscar</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-2">
          <a
            :href="`${api}/v1/campanhasLZV2/respostas/download?token=${$store.state.token}&de=${form.de}&ate=${form.ate}&centrocusto=${centrocusto}`"
            role="button"
            type="button"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Download</span>
          </a>
        </div>
      </div>
    </form>

    <div class="flex flex-col mt-3">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Data
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    TELEFONE
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    RESPOSTA
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="el in respostas" :key="el._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ moment(el.data).format("DD/MM/YYYY") }}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ el.telefone }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <!-- RESPOSTAS DE MENSAGEM MENCIONADA -->
                    <p
                      class="bg-blue-500 border-2 border-blue-600 text-white p-2 mb-2 rounded-lg"
                      v-if="el.quotedMsg"
                    >
                      <span v-if="el.quotedType === 'chat'">{{ el.quotedMsg }}</span>
                      <span v-if="el.quotedType !== 'chat'">
                        <img
                          v-if="el.mensagem"
                          style="height: 50px"
                          alt="img"
                          className="rounded mb-2"
                          :src="`data:image/jpeg;base64,${el.quotedMsg}`"
                        />
                      </span>
                      <br />
                    </p>

                    <!-- CHAT OU CONTATO -->
                    <p
                      v-if="
                        (!el.type || el.type === 'chat') && !el.mensagem.match(/VCARD/gim)
                      "
                      v-html="getHtmlFrom(el.mensagem)"
                    ></p>
                    <p
                      v-if="
                        (!el.type || el.type === 'vcard') && el.mensagem.match(/VCARD/gim)
                      "
                      v-html="getHtmlFrom(getVcard(el.mensagem))"
                    ></p>

                    <!-- IMAGEM -->
                    <a
                      v-if="el.type === 'image'"
                      target="blank"
                      :href="`${apilz}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`"
                    >
                      <img
                        style="height: 250px"
                        alt="img"
                        className="rounded mb-2"
                        :src="`${apilz}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`"
                      />
                    </a>

                    <img
                      style="height: 250px"
                      v-if="el.type === 'image_file'"
                      alt="img"
                      className="rounded mb-2"
                      :src="el.base64"
                    />

                    <!-- STICKER -->
                    <a
                      v-if="el.type === 'sticker'"
                      target="blank"
                      :href="`${apilz}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`"
                    >
                      <img
                        style="height: 250px"
                        alt="img"
                        className="rounded mb-2"
                        :src="`${apilz}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`"
                      />
                    </a>

                    <!-- DOCUMENTOS COM PREVIEW -->
                    <a
                      v-if="el.type === 'document' && el.mensagem"
                      target="blank"
                      :href="`${apilz}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`"
                    >
                      <img
                        v-if="el.mensagem"
                        style="height: 250px"
                        alt="img"
                        className="rounded mb-2"
                        :src="`data:image/jpeg;base64,${el.mensagem}`"
                      />
                    </a>

                    <!-- LOCALIZAÇÃO -->
                    <a
                      v-if="el.type === 'location' && el.mensagem"
                      target="blank"
                      :href="`https://www.google.com/maps?q=${el.lat},${el.lng}&z=17&hl=pt-BR`"
                    >
                      <img
                        v-if="el.mensagem"
                        style="height: 100px"
                        alt="img"
                        className="rounded mb-2"
                        :src="`data:image/jpeg;base64,${el.mensagem}`"
                      />
                    </a>

                    <!-- DOCUMENTOS SEM PREVIEW -->
                    <a
                      v-if="el.type === 'document' && !el.mensagem"
                      target="blank"
                      :href="`${apilz}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats&original=${el.filename}`"
                    >
                      <b>Documento:</b>
                      <br />{{ el.originalname ? el.originalname : el.filename }}
                    </a>

                    <a
                      v-if="el.type === 'document_file' && !el.mensagem"
                      target="blank"
                      :href="m.base64"
                    >
                      <b>Documento:</b>
                      <br />{{ el.originalname ? el.originalname : "" }}
                    </a>

                    <!-- AUDIOS -->
                    <audio controls v-if="el.type === 'ptt' || el.type === 'audio'">
                      <source
                        :src="`${apilz}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`"
                        :type="el.mimetype"
                      />
                      Your browser does not support the audio element.
                    </audio>

                    <!-- VIDEOS -->
                    <video controls v-if="el.type === 'video'">
                      <source
                        :src="`${apilz}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`"
                        :type="el.mimetype"
                      />
                      Your browser does not support the video element.
                    </video>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            v-model="page"
            :per-page="perPage"
            :records="total"
            @paginate="setPage"
            class="pagination"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vCard from "vcard-parser";
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: [{ url: "/respostas", value: "Respostas" }],
      route: "respostas",
      apilz: window.location.hostname === 'uno.trestto.com.br' ? 'https://uno.trestto.com.br/api' : process.env.VUE_APP_API,
      moment: moment,
      respostas: [],
      centroscusto: [],
      centrocusto: null,
      role: "user",
      page: 1,
      perPage: 25,
      form: {
        busca: "",
        de: moment().startOf("d").format("YYYY-MM-DDTHH:mm"),
        ate: moment().endOf("d").format("YYYY-MM-DDTHH:mm"),
      },
      total: 0,
      vCard,
    };
  },
  methods: {
    getHtmlFrom(str) {
      if (str) {
        return str
          .replace(/(?:\*)([^*]*)(?:\*)/gm, "<strong>$1</strong>")
          .replace("{#", "<b>Contato: </b>")
          .replace("#}", "")
          .replace(/(?:_)([^_]*)(?:_)/gm, "<i>$1</i>")
          .replace(/(?:~)([^~]*)(?:~)/gm, "<strike>$1</strike>")
          .replace(/\n/gim, "<br/>")
          .replace(/(?:```)([^```]*)(?:```)/gm, "<tt>$1</tt>");
      } else {
        return str;
      }
    },
    getVcard(str) {
      const parsed = vCard.parse(str);
      return `*CONTATO*
              ${parsed.n[0].value[1]} ${parsed.n[0].value[0]}
              ${parsed.tel[0].value}`;
    },
    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/campanhasLZV2/respostas/list`, {
        busca: this.form.busca,
        de: this.form.de,
        ate: this.form.ate,
        centrocusto: this.centrocusto,
        limit,
        skip,
      });
      this.respostas = listReq.data.data;
      this.total = listReq.data.total;
    },
    async list() {
      this.setPage(1);
    },
  },
  async beforeMount() {
    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;

    if (this.$store.state.user.centrocusto) {
      this.centrocusto = this.$store.state.user.centrocusto;
    }

    this.list();
  },
};
</script>
